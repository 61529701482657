<template>
    <section>
        <div id="sob-splash-screen"></div>

    </section>
</template>
  
  <script>
  import _ from "lodash";
  import { mapGetters, mapActions } from "vuex";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  export default {
    name: "Splash",
    components: {
    },
    data() {
        return{}
    },
    computed: {
    },
    mounted() {
        setCurrentPageTitle("Splash Screen");
        window.SplashScreenLibrary.init([
           "sob-splash-screen",
           "https://app.saasonboard.com/",
           "aXf00FN0Qe6JQkm",
           "splash-screen-iframe"
       ]);
    },
    methods: {
    },
  };
  </script>